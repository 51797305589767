import React, {useState, useContext} from 'react';
import * as Styles from '../styles/feedback.module.scss';
import {LanguageContext} from '../contexts/language';
import {ambasForm} from '../contents/ambassadors';
import bg from '../images/collage.png';

const AmbassadorForm = () => {

    const lang = useContext(LanguageContext);
    const contents = lang.language ? ambasForm.tamil : ambasForm.english;

    const [name, setName] = useState("");
    const [mobile, setMobile] = useState("");
    const [address, setAddress] = useState("");
    const [latitude, setLatitude] = useState("");
    const [longitude, setLongitude] = useState("");

    const [submitting, setsubmitting] = useState(false);

    const setLocation = () => {
        if(!navigator.geolocation) {
            alert("Please use a browser that allows location sharing");
        }
        else {
            navigator.geolocation.getCurrentPosition(position => {
                console.log(position);
                setLatitude(position.coords.latitude);
                setLongitude(position.coords.longitude);
            }, () => {
                setLatitude("unavailable");
                setLongitude("unavailable");
            });
        }
    }

    React.useEffect(() => {
        setLocation();
    },[]);

    const handleSubmit = e => {
        e.preventDefault();

        setsubmitting(true);

        let formdata = new FormData();
        formdata.append('name', name);
        formdata.append('mobile', mobile);
        formdata.append('address', address);
        formdata.append('latitude', latitude);
        formdata.append('longitude', longitude);

        fetch('https://script.google.com/macros/s/AKfycbwvh4ufHHTqHCH5CPAqZXBFk8zGWDzHSLeU1NJfunPBcbqIfM5IctB66igZbPzoMs8doA/exec', {
            method: 'POST',
            body: formdata
        }).then(response => {
            if(response.ok){
                alert("Thank you for sharing your feedback. We will get back to you asap.");
                setName("");
                setMobile("");
                setAddress("");
                setLatitude("");
                setLongitude("");
                setsubmitting(false);
            }
            else {
                alert("There was an errer submitting your request. Please try again later.");
                throw new Error(response);
            }
        }).catch(error=>console.error(error))
    }

    return (
        <section className={Styles.main} id="newAmbassador"
            style={{
                backgroundImage: `url(${bg})`,
                backgroundPosition: "center",
                backgroundSize: "cover"
            }}
        >
            {submitting && <div className={Styles.submitting}><div className="spin"></div></div>}
            <h1>Join Us</h1>
            <div className={Styles.container}>
                <div className={Styles.info}>
                    {contents.p}
                </div>
                <form  onSubmit={handleSubmit}>
                    <div className={Styles.group}>
                    <label htmlFor="ambassador_name">Name: </label>
                    <input type="text" name="ambassador_name" placeholder="Enter your name" value={name} onChange={e=>setName(e.target.value)}/>
                    </div>

                    <div className={Styles.group}>
                    <label htmlFor="ambassador_mobile">Mobile :</label>
                    <input type="number" name="ambassador_mobile" placeholder="Mobile number" value={mobile} onChange={e=>setMobile(e.target.value)} required/>
                    </div>

                    <div className={Styles.feedback}>
                    <label htmlFor="ambassador_address">Address :</label>
                    <textarea name="ambassador_address"  placeholder="Enter your Address" value={address} onChange={e=>setAddress(e.target.value)} required></textarea>
                    </div>

                    <button type="submit">Submit</button>
                </form>
            </div>
        </section>
    );
}

export default AmbassadorForm;