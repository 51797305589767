let content = {
    tamil: {
        h: "எங்கள் பிராண்ட் தூதர்கள்",
        p: "நாங்கள் உங்களுடன் வளர்கிறோம். எங்கள் பிரீமியம் தயாரிப்புகளின் விநியோகஸ்தராக வெளிச்சத்திற்கு கொண்டு வருவதன் மூலம் அறிவொளி பெற்ற தொழில்முனைவோருக்கு (பெண் தொழில்முனைவோர்) வீட்டு தயாரிப்பாளர்களுக்கு பேட்டர் ஹவுஸ் அதிகாரம் அளிக்கிறது."
    },
    english: {
        h: "Our Brand Ambassadors",
        p: ["Ready to earn from home?",
            "Wish to become our ambassador?",
            "CLICK HERE"]
    }
}

export const ambasForm = {
    tamil: {
        p: "Batter House welcomes women and the specially abled to be a part in our success story. With basic investment and sincere work. Batter House provides you an opportunity to be financially independent and progressive.Empowering ONLY WOMEN, you may please contact us at info@batterhouse.in"
    },
    english: {
        p: "Batter House welcomes women and the specially abled to be a part in our success story. With basic investment and sincere work. Batter House provides you an opportunity to be financially independent and progressive.Empowering ONLY WOMEN, you may please contact us at info@batterhouse.in"
    }
}

export default content;