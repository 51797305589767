import React from 'react';

import Header from '../components/header';
import Footer from '../sections/footer';
import Seo from '../components/seo';
// Contexts
import LanguageProvider from '../contexts/language';
import {NavContextProvider} from '../contexts/navigation';
import AmbassadorForm from '../sections/ambassadorForm';
import bg from '../images/collage.png';

function FeedbackPage(props) {
    return (
        <div
            style={{
                backgroundImage: `url(${bg})`,
                backgroundPosition: "center",
                backgroundSize: "cover"
            }}
        >
            <LanguageProvider>
                <NavContextProvider>
                    <Seo title="Feedback"/>
                    <Header/>                  
                    <AmbassadorForm/>
                    <Footer/>
                </NavContextProvider>
            </LanguageProvider>
        </div>
    );
}

export default FeedbackPage;